<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input-number
          title="金额"
          v-model:value="money"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { FinAccountClass } from '@/apis/fin'

const api = new FinAccountClass()
export default defineComponent({
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')
    const money = ref(0)
    const type = ref(0)
    const finAccountId = ref('')

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      finAccountId,
      money,
      type,
      onClose
    }
  },

  methods: {
    open (type, finAccountId) {
      this.finAccountId = finAccountId
      this.type = type
      this.title = type === 0 ? '减扣金额' : '增加金额'
      this.visible = true
    },
    onSubmitFun () {
      api.balance({
        finAccountId: this.finAccountId,
        type: this.type,
        money: this.money
      }).then(() => {
        this.$message.success('修改成功')
        this.onClose()
        this.$emit('ok')
      })
    }
  }
})
</script>
